import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: _vm.$t("dcReview.detailTitleCompleted", {
        title: _vm.title,
        editId: _vm.editId
      }),
      width: _vm.width,
      visible: _vm.visible,
      destroyOnClose: true,
      footer: null,
      bodyStyle: {
        "min-height": "650px"
      },
      centered: ""
    },
    on: {
      cancel: _vm.onClose
    }
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.spinning || _vm.loading
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      layout: "vertical",
      labelCol: {
        style: "width: 100px"
      }
    }
  }, [_c("a-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", {
    attrs: {
      label: _vm.$t("dcReview.detailForm.title")
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["title", {
        rules: [{
          required: false,
          message: _vm.$t("dcReview.detailForm.message04")
        }],
        initialValue: _vm.item.title
      }],
      expression: "[\n                'title',\n                {\n                  rules: [{ required: false, message: $t('dcReview.detailForm.message04') }],\n                  initialValue: item.title,\n                },\n              ]"
    }],
    attrs: {
      "auto-size": {
        minRows: 1,
        maxRows: 6
      },
      placeholder: _vm.$t("dcReview.detailForm.placeholder01"),
      disabled: ""
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: _vm.$t("dcReview.detailForm.nickName")
    }
  }, [_c("div", {
    staticClass: "form-row-item"
  }, [_c("span", [_vm.item.usertype > 0 ? _c("img", {
    staticStyle: {
      position: "relative",
      top: "-2px",
      "margin-right": "2px"
    },
    attrs: {
      src: _vm.certified,
      alt: ""
    }
  }) : _vm._e(), _vm._v(_vm._s(_vm.item.username))]), _c("span", {
    staticClass: "cell"
  }, [_vm._v(_vm._s(_vm.$t("dcReview.fans")) + "："), _c("Follows", {
    attrs: {
      num: _vm.item.fansnum
    }
  })], 1), _c("span", {
    staticClass: "cell"
  }, [_vm._v(_vm._s(_vm.$t("dcReview.detailForm.userId")) + _vm._s(_vm.item.userid))])])]), _c("a-form-item", {
    attrs: {
      label: _vm.$t("dcReview.detailForm.category")
    }
  }, [_c("a-cascader", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["labelids", {
        rules: [{
          required: false,
          message: _vm.$t("dcReview.detailForm.message05")
        }, {
          validator: _vm.customValidator
        }],
        initialValue: _vm.labelids
      }],
      expression: "[\n                'labelids',\n                {\n                  rules: [\n                    { required: false, message: $t('dcReview.detailForm.message05') },\n                    { validator: customValidator },\n                  ],\n                  initialValue: labelids,\n                },\n              ]"
    }],
    attrs: {
      options: _vm.tags,
      "show-search": {
        filterCascader: _vm.filterCascader
      },
      placeholder: _vm.$t("dcReview.detailForm.placeholder02"),
      fieldNames: {
        label: "name",
        value: "id",
        children: "children"
      }
    },
    on: {
      change: _vm.reValidate
    },
    scopedSlots: _vm._u([{
      key: "displayRender",
      fn: function fn(_ref) {
        var labels = _ref.labels,
          selectedOptions = _ref.selectedOptions;
        return [labels.length ? _c("a-tag", {
          staticStyle: {
            height: "20px"
          }
        }, [_vm._v(" " + _vm._s(labels[labels.length - 1]) + " ")]) : _vm._e()];
      }
    }])
  })], 1), _c("a-form-item", {
    attrs: {
      label: _vm.$t("dcReview.detailForm.timeliness")
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["prescriptiontype", {
        rules: [{
          required: false,
          message: _vm.$t("dcReview.detailForm.message01")
        }, {
          validator: _vm.customValidator
        }],
        initialValue: _vm.prescriptiontypeValue.includes(_vm.item.prescriptiontype) ? _vm.item.prescriptiontype : undefined
      }],
      expression: "[\n                'prescriptiontype',\n                {\n                  rules: [\n                    { required: false, message: $t('dcReview.detailForm.message01') },\n                    { validator: customValidator },\n                  ],\n                  initialValue: prescriptiontypeValue.includes(item.prescriptiontype)\n                    ? item.prescriptiontype\n                    : undefined,\n                },\n              ]"
    }],
    attrs: {
      "button-style": "solid"
    },
    on: {
      change: _vm.reValidate
    }
  }, _vm._l(_vm.prescriptiontype, function (item, index) {
    return _c("a-radio-button", {
      key: index,
      attrs: {
        value: _vm.prescriptiontypeValue[index]
      }
    }, [_vm._v(_vm._s(item[_vm.prescriptiontypeValue[index]]))]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: _vm.$t("dcReview.detailForm.quality")
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["qualitytype", {
        rules: [{
          required: false,
          message: _vm.$t("dcReview.message06")
        }, {
          validator: _vm.customValidator
        }],
        initialValue: _vm.qualitytypesValue.includes(_vm.item.qualitytype) ? _vm.item.qualitytype : undefined
      }],
      expression: "[\n                'qualitytype',\n                {\n                  rules: [\n                    { required: false, message: $t('dcReview.message06') },\n                    { validator: customValidator },\n                  ],\n                  initialValue: qualitytypesValue.includes(item.qualitytype)\n                    ? item.qualitytype\n                    : undefined,\n                },\n              ]"
    }],
    attrs: {
      "button-style": "solid"
    },
    on: {
      change: _vm.reValidate
    }
  }, _vm._l(_vm.qualitytypes, function (item, index) {
    return _c("a-radio-button", {
      key: index,
      attrs: {
        value: _vm.qualitytypesValue[index]
      }
    }, [_vm._v(_vm._s(item[_vm.qualitytypesValue[index]]))]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: _vm.$t("dcReview.detailForm.issueCBK")
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["isgetcbk", {
        initialValue: [1, 2].includes(_vm.item.isgetcbk) ? _vm.item.isgetcbk : undefined
      }],
      expression: "[\n                'isgetcbk',\n                {\n                  initialValue: [1, 2].includes(item.isgetcbk)\n                    ? item.isgetcbk\n                    : undefined,\n                },\n              ]"
    }]
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(_vm._s(_vm.$t("dcReview.detailForm.no")))]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v(_vm._s(_vm.$t("dcReview.detailForm.yes")))])], 1)], 1), _c("a-form-item", {
    attrs: {
      label: _vm.$t("dcReview.detailForm.remark")
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["memo", {
        rules: [{
          required: false
        }],
        initialValue: _vm.item.memo
      }],
      expression: "[\n                'memo',\n                {\n                  rules: [{ required: false }],\n                  initialValue: item.memo,\n                },\n              ]"
    }],
    attrs: {
      placeholder: "",
      autocomplete: "off"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: " "
    }
  }, [_c("div", {
    staticClass: "actions"
  }, [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["dcMark:btn:mark"],
      expression: "['dcMark:btn:mark']"
    }],
    attrs: {
      type: "primary",
      loading: _vm.submiting,
      disabled: _vm.isdeal
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v(_vm._s(_vm.$t("dcReview.detailBtn.submit")))]), _c("a-button", {
    attrs: {
      type: "primary",
      ghost: ""
    },
    on: {
      click: _vm.handleNext
    }
  }, [_vm._v(_vm._s(_vm.$t("dcReview.detailBtn.next")))]), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["dcMark:btn:log"],
      expression: "['dcMark:btn:log']"
    }],
    on: {
      click: _vm.handlePrevieLog
    }
  }, [_vm._v(_vm._s(_vm.$t("dcReview.detailBtn.viewLogs")))])], 1)]), _c("div", {
    staticClass: "form-tags"
  }, _vm._l(_vm.item.items, function (it, index) {
    return _c("p", {
      key: index
    }, [_vm._v(" [" + _vm._s(_vm.$t("common.tag")) + "]：" + _vm._s(it.label) + "，[" + _vm._s(_vm.$t("dcReview.score")) + "]：" + _vm._s(it.confidence) + "，" + _vm._s(_vm.$t("common.location")) + "："), _c("a", {
      on: {
        click: function click($event) {
          return _vm.toPisition(it.time);
        }
      }
    }, [_vm._v(_vm._s(_vm.formatVideoTime(it.time)))])]);
  }), 0)], 1), _c("a-col", {
    attrs: {
      span: 12,
      push: 1
    }
  }, [_vm.item.type === 1 ? _c("div", {
    staticClass: "form-videos"
  }, [_c("video", {
    ref: "video",
    attrs: {
      src: _vm.item.contenturl,
      poster: _vm.item.coverurl,
      controls: "controls",
      controlslist: "nodownload"
    }
  })]) : _vm.item.type === 2 ? _c("div", [_c("p", [_vm._v(_vm._s(_vm.$t("common.total")) + "：" + _vm._s(_vm.item.contenturl.length))]), _c("div", {
    staticClass: "imgs-wrap"
  }, _vm._l(_vm.item.contenturl, function (Itm, Idx) {
    return _c("img", {
      key: Idx,
      staticClass: "art-img",
      attrs: {
        src: Itm,
        alt: "图片"
      },
      on: {
        click: function click($event) {
          return _vm.previewImg(_vm.item.contenturl, "pic", Idx);
        }
      }
    });
  }), 0)]) : _vm._e()])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };