// 预览功能封装
export default {
  data: function data() {
    return {
      preVisible: false,
      previewSource: "",
      previewVisible: false,
      previewType: 'pic',
      slideNumber: 0,
      certified: require("@/assets/svgs/icon_certified.svg")
    };
  },
  methods: {
    previewImg: function previewImg(data) {
      var previewType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'pic';
      var slideNumber = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
      if (previewType == 'pic') {
        var images = [];
        if (Array.isArray(data)) {
          images = data;
        } else {
          images = data.split(',');
        }
        this.$viewerApi({
          options: {
            toolbar: true,
            navbar: false,
            initialViewIndex: slideNumber
          },
          images: images
        });
      } else {
        this.previewSource = data;
        this.previewVisible = true;
        this.previewType = previewType;
        this.slideNumber = slideNumber;
      }
    },
    previewHandleCancel: function previewHandleCancel() {
      this.previewVisible = false;
    }
  }
};