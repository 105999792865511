var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v(_vm._s(_vm.$t("common.search")))]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v(_vm._s(_vm.$t("common.reset")))])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("DTab", {
    attrs: {
      defaultIndex: _vm.defaultIndex,
      titles: _vm.types,
      handleClick: _vm.tabClick
    }
  }), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return i;
      },
      loading: _vm.loading,
      scroll: {
        x: "1472px"
      }
    },
    on: {
      change: _vm.tableChange
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref, index) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record) {
          return _c("div", {
            key: index
          }, [key == "action" ? [_c("a", {
            on: {
              click: function click($event) {
                return _vm.handleAction("detail", record);
              }
            }
          }, [_vm._v(_vm._s(_vm.$t("dcReview.mark")))]), _c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["dcMark:btn:log"],
              expression: "['dcMark:btn:log']"
            }],
            on: {
              click: function click($event) {
                return _vm.handleAction("log", record);
              }
            }
          }, [_vm._v(_vm._s(_vm.$t("common.logs")))])] : key == "type" ? [_vm._v(" " + _vm._s(_vm.typeListObj[text] || "---") + " ")] : key == "name" ? [_c("div", {
            staticClass: "ellipsis-5"
          }, [_c("a-popover", [_c("template", {
            slot: "content"
          }, [_c("img", {
            staticStyle: {
              "max-width": "150px",
              "max-height": "150px"
            },
            attrs: {
              src: record.coverurl
            }
          })]), _c("a", {
            attrs: {
              href: "javascript:void(0);"
            }
          }, [_vm._v("[" + _vm._s(_vm.$t("common.cover")) + "]")])], 2), record.title ? _c("a-tooltip", {
            attrs: {
              placement: "topLeft"
            }
          }, [_c("template", {
            slot: "title"
          }, [_c("div", {
            staticStyle: {
              "max-height": "50vh",
              overflow: "auto"
            }
          }, [_vm._v(_vm._s(record.title))])]), _vm._v(" " + _vm._s(record.title) + " ")], 2) : [_vm._v("---")]], 2)] : key == "sourcetype" ? [record.sourcetype == 1 ? _c("span", [_vm._v("IOS")]) : _vm._e(), record.sourcetype == 2 ? _c("span", [_vm._v("Admin")]) : _vm._e(), record.sourcetype == 3 ? _c("span", [_vm._v(" Android")]) : _vm._e()] : key == "status" ? [_c("span", {
            staticClass: "color-green"
          }, [_vm._v(_vm._s(_vm.statusListObj[text]))])] : key == "username" ? [_c("a-popover", [_c("template", {
            slot: "content"
          }, [_c("p", {
            directives: [{
              name: "clipboard",
              rawName: "v-clipboard:copy",
              value: record.userid,
              expression: "record.userid",
              arg: "copy"
            }, {
              name: "clipboard",
              rawName: "v-clipboard:success",
              value: _vm.copySuccess,
              expression: "copySuccess",
              arg: "success"
            }]
          }, [_vm._v(" " + _vm._s(record.userid) + " ")])]), record.usertype > 0 ? _c("img", {
            staticStyle: {
              position: "relative",
              top: "-2px",
              "margin-right": "2px"
            },
            attrs: {
              src: _vm.certified,
              alt: ""
            }
          }) : _vm._e(), _vm._v(" " + _vm._s(record.username) + " ")], 2), _c("div", [_vm._v(" " + _vm._s(_vm.$t("dcReview.fans")) + "："), _c("Follows", {
            attrs: {
              num: record.fansnum
            }
          })], 1)] : key == "state" ? [_vm._v(_vm._s(_vm.state))] : [_vm._v(_vm._s(record[key] || "---"))]], 2);
        }
      };
    })], null, true)
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  }), _vm.modalVisible ? _c("Detail", {
    ref: "detail",
    attrs: {
      visible: _vm.modalVisible,
      loading: _vm.loading,
      editId: _vm.editId
    },
    on: {
      next: _vm.handleNext,
      previeLog: _vm.handlePrevieLog,
      cancel: _vm.handleFormCancel,
      submit: _vm.handleFormSubmit
    }
  }) : _vm._e(), _vm.modalVisible2 ? _c("DPopup", {
    attrs: {
      title: _vm.modalTitle,
      comVisible: _vm.modalVisible2,
      isClosable: true,
      "z-index": 1001,
      modalWidth: "1000px"
    },
    on: {
      cancel: function cancel($event) {
        _vm.modalVisible2 = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [_c("Log", {
          attrs: {
            editId: _vm.editId,
            action: _vm.action
          },
          on: {
            cancel: _vm.handleFormCancel
          }
        })];
      },
      proxy: true
    }], null, false, 1490245591)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };