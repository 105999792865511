import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.splice.js";
/**
 * 全选/批量等操作的公用mixin
 */
import { CHECKALL } from "./buttons";
import { mapState } from "vuex";
export default {
  model: {},
  props: {},
  // 排序呗
  watch: {
    sortOrder: function sortOrder(val) {
      this.getList();
    },
    sortField: function sortField(val) {
      this.getList();
    }
  },
  data: function data() {
    return {
      dataSource: [],
      selectedRows: [],
      selectedRowKeys: [],
      rowKey: "",
      sortOrder: undefined,
      sortField: undefined,
      page: 1,
      size: 20,
      total: 0,
      certified: require("@/assets/svgs/icon_certified.svg")
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState("base", ["listened"])), {}, {
    module: function module() {
      return this.$route.name;
    }
  }),
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    if (!this.listened) {
      // console.log('看看触发了几次呢', this.$route);
      this.$store.commit("base/setListened", true);
      window.GLOBAL.vbus.$on("topBtnClicked", function (data) {
        var module = data.module,
          action = data.action;
        _this.mixinBtnClicked(module, action);
      });
    }
  },
  activated: function activated() {
    var _this2 = this;
    if (!this.listened) {
      // console.log('看看activated触发了几次呢', this.$route);
      this.$store.commit("base/setListened", true);
      window.GLOBAL.vbus.$on("topBtnClicked", function (data) {
        var module = data.module,
          action = data.action;
        _this2.mixinBtnClicked(module, action);
      });
    }
  },
  methods: {
    // mixin里边的按钮点击效果
    mixinBtnClicked: function mixinBtnClicked(module, action) {
      var _this3 = this;
      // console.log('点击到了', module, action, this.module);
      if (module != this.module) return false;
      if (action == CHECKALL) {
        var data = [];
        this.dataSource.map(function (it, index) {
          if (_this3.rowKey) {
            data.push(it[_this3.rowKey]);
          } else {
            data.push(index);
          }
        });
        this.selectedRowKeys = data;
        this.selectedRows = this.dataSource;
      } else {
        this.pageBtnClicked(module, action);
      }
    },
    // 该方法必须自己去实现
    pageBtnClicked: function pageBtnClicked(module, action) {},
    // 获取选择的结果，key为传入的键name
    getAllCheckedKey: function getAllCheckedKey(key) {
      var _this4 = this;
      var data = [];
      this.dataSource.forEach(function (it, index) {
        if (_this4.selectedRowKeys.includes(index) && it[key]) {
          data.push(it[key]);
        }
      });
      return data;
    },
    // 这个是表格的选中操作
    onSelectChange: function onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 表格change事件，分页、排序、过滤等
    tableChange: function tableChange(pagination, filters, sorter) {
      var order = sorter.order,
        field = sorter.field;
      this.sortField = field;
      this.sortOrder = order; // "descend"|"ascend"|undefined
    },
    getList: function getList() {},
    tableRowToggleSelect: function tableRowToggleSelect(record, index) {
      var _this5 = this;
      return {
        on: {
          click: function click() {
            var rowKeys = _this5.selectedRowKeys;
            if (rowKeys.length > 0 && rowKeys.includes(index)) {
              rowKeys.splice(rowKeys.indexOf(index), 1);
            } else {
              rowKeys.push(index);
            }
            _this5.selectedRowKeys = rowKeys;
          }
        }
      };
    }
  },
  beforeDestroy: function beforeDestroy() {
    // 看看有销毁么？？
    // console.log('看看有销毁么？？', this.$route)
    window.GLOBAL.vbus.$off("topBtnClicked");
    this.$store.commit("base/setListened", false);
  },
  deactivated: function deactivated() {
    // console.log('看看有被deactivated处理了么', this.$route)
    window.GLOBAL.vbus.$off("topBtnClicked");
    this.$store.commit("base/setListened", false);
  }
};