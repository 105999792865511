import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import { DcVideoApi } from "@/api";
import { logColumns } from "../config";
export default {
  name: "DcReviewLog",
  components: {},
  props: {
    editId: [String],
    action: {
      type: String,
      default: function _default() {
        return "pass";
      }
    }
  },
  data: function data() {
    var _this = this;
    return {
      spinning: false,
      logColumns: logColumns.map(function (v) {
        v.title = _this.$i18n.t(v.title);
        return v;
      }),
      dataSource: [],
      // 模板列表
      total: 0,
      page: 1,
      size: 30
    };
  },
  computed: {
    module: function module() {
      return this.$route.name;
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this2 = this;
      this.spinning = true;
      var params = {
        videoid: this.editId,
        page: this.page,
        size: this.size,
        type: 2
      };
      DcVideoApi.getLogs(_objectSpread({}, params)).then(function (res) {
        _this2.spinning = false;
        _this2.dataSource = res.list || [];
        _this2.total = res.total || 0;
      }).catch(function () {});
    },
    cancel: function cancel() {
      this.$emit("cancel");
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.size = size;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    }
  }
};