import i18n from '@/lang';
/* 
 * 定义路由页面对应的权限-固定显示在面包屑中
 * path: 必须和路由一一对应
 * 根据module+action自动生成按钮权限: module:btn:action 如： 'autoBidding:btn:checkall'
 * 通过vbus进行通信，main.js中有定义，触发事件：window.GLOBALS.vbus.$emit('topBtnClicked', {module: 'autoBidding', action: 'checkall'})
*/

var CHECKALL = 'checkall'; // 全选
var BATCH_PASS = 'batchPass'; // 批量通过
var BATCH_UNPASS = 'batchUnpass'; // 批量驳回
var ADD = 'add'; // 新增
var OTHER = 'other'; // 其它
var RESET = 'reset'; // 重置

var buttons = [{
  // 视频审核
  path: '/dcReview/index',
  module: 'dcReview',
  actions: [{
    name: i18n.tc('common.selectAll'),
    type: 'primary',
    action: CHECKALL
  }, {
    name: i18n.tc('common.batchPass'),
    type: 'default',
    action: BATCH_PASS
  }, {
    name: i18n.tc('common.batchReject'),
    type: 'danger',
    action: BATCH_UNPASS
  }]
}, {
  // 内容特征库
  path: '/dcFeatures/index',
  module: 'dcFeatures',
  actions: [{
    name: '全选',
    type: 'primary',
    action: CHECKALL
  }, {
    name: '批量启用',
    type: 'default',
    action: BATCH_PASS
  }, {
    name: '批量禁用',
    type: 'danger',
    action: BATCH_UNPASS
  }, {
    name: '新增特征组',
    type: 'primary',
    action: ADD
  }]
}, {
  // 内容特征库-二级详情列表
  path: '/dcFeatures/sublist',
  module: 'dcSubFeatures',
  actions: [{
    name: '全选',
    type: 'primary',
    action: CHECKALL
  }, {
    name: '批量恢复',
    type: 'default',
    action: BATCH_PASS
  }, {
    name: '批量删除',
    type: 'danger',
    action: BATCH_UNPASS
  }, {
    name: '添加内容ID',
    type: 'primary',
    action: ADD
  }]
}, {
  // 视频标签管理
  path: '/dc-video-label',
  module: 'DcVideoLabel',
  actions: [{
    name: '全选',
    type: 'primary',
    action: CHECKALL
  }, {
    name: '批量恢复',
    type: 'default',
    action: BATCH_PASS
  }, {
    name: '批量删除',
    type: 'danger',
    action: BATCH_UNPASS
  }, {
    name: '标签管理',
    type: 'primary',
    action: OTHER
  }]
}, {
  // 视频举报
  path: '/dc-video-report',
  module: 'DcVideoReport',
  actions: [{
    name: i18n.tc('common.selectAll'),
    type: 'primary',
    action: CHECKALL
  }, {
    name: i18n.tc('common.batchValid'),
    type: 'default',
    action: BATCH_PASS
  }, {
    name: i18n.tc('common.batchInvalid'),
    type: 'danger',
    action: BATCH_UNPASS
  }]
}, {
  // 视频管理
  path: '/dcVideo/index',
  module: 'dcVideo',
  actions: [{
    name: i18n.tc('common.selectAll'),
    type: 'primary',
    action: CHECKALL
  }, {
    name: i18n.tc('common.batchPass'),
    type: 'default',
    action: BATCH_PASS
  }, {
    name: i18n.tc('common.batchReject'),
    type: 'danger',
    action: BATCH_UNPASS
  }, {
    name: i18n.tc('dcReview.add'),
    type: 'primary',
    action: ADD
  }]
}, {
  // 评论管理
  path: '/dc/comment',
  module: 'DcCommentManage',
  actions: [{
    name: i18n.tc('common.selectAll'),
    type: 'primary',
    action: CHECKALL
  }, {
    name: i18n.tc('common.batchPass'),
    type: 'default',
    action: BATCH_PASS
  }, {
    name: i18n.tc('common.batchDelete'),
    type: 'danger',
    action: BATCH_UNPASS
  }]
}, {
  // 评论管理
  path: '/authentication/index',
  module: 'authentication',
  actions: [{
    name: '新增',
    type: 'primary',
    action: ADD
  }]
}, {
  // 项目方视频活动管理
  path: '/rain/index',
  module: 'rain',
  actions: [{
    name: '新增',
    type: 'primary',
    action: ADD
  }]
}, {
  // 新闻文章配置
  path: '/newsletter/index',
  module: 'newsletter',
  actions: [{
    name: '新增',
    type: 'primary',
    action: ADD
  }]
}, {
  // 可疑设备管理
  path: '/userequipment/index',
  module: 'userequipment',
  actions: [{
    name: '全选',
    type: 'primary',
    action: CHECKALL
  }, {
    name: '批量封禁',
    type: 'danger',
    action: BATCH_UNPASS
  }, {
    name: '导出',
    type: 'primary',
    action: OTHER
  }]
}, {
  // 黑名单管理
  path: '/blacklist/index',
  module: 'blacklist',
  actions: [{
    name: '新增',
    type: 'primary',
    action: ADD
  }]
}, {
  path: '/seriesV2/add',
  module: 'seriesV2',
  actions: [{
    name: '重置',
    type: 'default',
    action: RESET
  }, {
    name: '提交',
    type: 'primary',
    action: OTHER
  }]
}, {
  path: '/seriesV2/edit',
  module: 'seriesV2',
  actions: [{
    name: '重置',
    type: 'default',
    action: RESET
  }, {
    name: '提交',
    type: 'primary',
    action: OTHER
  }]
}];
export { buttons, CHECKALL, BATCH_PASS, BATCH_UNPASS, ADD, OTHER, RESET };