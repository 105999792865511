import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import Follows from "./follows.vue";
import { DcVideoApi } from "@/api";
import { flattenTreeData } from "@/utils/help";
import previewMixin from "@/utils/previewMixin";
export default {
  components: {
    Follows: Follows
  },
  mixins: [previewMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      // 标题
      type: String,
      default: function _default() {
        return this.$t('dcReview.detailDefaultTitle');
      }
    },
    editId: [String, Number],
    width: {
      type: Number,
      default: 1200
    }
  },
  computed: {
    // newTitle() {
    //   return `${this.title} 【视频ID: ${this.editId}】`;
    // },
    labelids: function labelids() {
      var ids = (this.item.labels || []).map(function (item) {
        return item.id;
      });
      if (!ids || ids.length == 0) return [];
      var labelid = ids[0];
      function getAllparent(flatten, id) {
        var data = [];
        var cur = flatten.find(function (it) {
          return it.id == id;
        });
        if (cur) {
          data.push(cur.id);
          // console.log(cur);
          if (cur.parentid) {
            data.push.apply(data, _toConsumableArray(getAllparent(flatten, cur.parentid)));
          }
        }
        return data;
      }
      var labelids = getAllparent(this.flatten, labelid);
      // console.log(labelid, labelids, this.flatten);
      return labelids.reverse();
    },
    flatten: function flatten() {
      return flattenTreeData(this.tags);
    }
  },
  watch: {
    visible: function visible(newVal) {
      if (newVal) {
        this.submiting = false;
        this.find();
      }
    }
  },
  mounted: function mounted() {
    this.getLabelItems();
    this.find();
    this.loadTags();
  },
  data: function data() {
    return {
      form: this.$form.createForm(this),
      spinning: false,
      submiting: false,
      isdeal: false,
      item: {},
      timer: null,
      tags: [],
      previewVisible: false,
      prescriptiontype: [],
      prescriptiontypeValue: [],
      qualitytypes: [],
      qualitytypesValue: []
    };
  },
  methods: {
    customValidator: function customValidator(rules, value, callback) {
      // 3项的值
      var labelids = this.form.getFieldValue("labelids");
      var prescriptiontype = this.form.getFieldValue("prescriptiontype");
      var qualitytype = this.form.getFieldValue("qualitytype");
      if (!labelids.length && !prescriptiontype && !qualitytype) {
        callback(this.$t("dcReview.detailForm.warining01"));
      }
      callback();
    },
    reValidate: function reValidate() {
      var _this = this;
      this.$nextTick(function () {
        _this.form.validateFields();
      });
    },
    onClose: function onClose() {
      this.$emit("cancel");
    },
    handlePrevieLog: function handlePrevieLog() {
      this.$emit("previeLog");
    },
    handleNext: function handleNext() {
      this.form.resetFields();
      this.$emit("next");
    },
    onSubmit: function onSubmit() {
      var _this2 = this;
      this.form.validateFields(function (err, values) {
        if (!err) {
          var params = _objectSpread({}, values);
          if (!values.isgetcbk) delete params.isgetcbk;
          params.videoids = [_this2.editId];
          delete params.title;

          // console.log(params.labelids);
          params.labelids = params.labelids.length ? [params.labelids[params.labelids.length - 1]] : [];
          // console.log(params.labelids);

          if (!params.prescriptiontype) params.prescriptiontype = 0;
          if (!params.qualitytype) params.qualitytype = 0;
          _this2.submiting = true;
          DcVideoApi.batchmark(_objectSpread({}, params)).then(function () {
            _this2.toast(_this2.$t('common.success'), "success");
            _this2.submiting = false;
            _this2.isdeal = true;
            _this2.$emit("submit");
          }).catch(function () {
            _this2.submiting = false;
          });
        }
      });
    },
    // 视频定位
    toPisition: function toPisition(seconds) {
      var video = this.$refs.video;
      video.pause();
      video.currentTime = seconds;
    },
    // 格式化视频时间
    formatVideoTime: function formatVideoTime(seconds) {
      function fillZero(num) {
        if (num >= 10) return "".concat(num);else return "0".concat(num);
      }
      seconds = Math.floor(seconds);
      var hh = Math.floor(seconds / 3600);
      seconds = seconds % 3600;
      var mm = Math.floor(seconds / 60);
      var ss = seconds % 60;
      if (hh > 0) return "".concat(fillZero(hh), ":").concat(fillZero(mm), ":").concat(fillZero(ss));else return "".concat(fillZero(mm), ":").concat(fillZero(ss));
    },
    // 下拉框筛选
    filterOption: function filterOption(input, option) {
      if (this.searchMode == "remote") return true;
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    filterCascader: function filterCascader(inputValue, path) {
      return path.some(function (option) {
        return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
      });
    },
    find: function find() {
      var _this3 = this;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.spinning = true;
      this.timer = setTimeout(function () {
        DcVideoApi.getdetails({
          id: _this3.editId
        }).then(function (data) {
          _this3.isdeal = data.status > 0 ? true : false;
          if (data.type === 2) {
            // 类型 1视频 2图文
            data.contenturl = data.contenturl.split(',');
          }
          _this3.item = data;
          _this3.spinning = false;
        }).catch(function () {
          _this3.spinning = false;
        });
      }, 200);
    },
    loadTags: function loadTags() {
      var _this4 = this;
      var params = {
        ispagination: false,
        sceneid: 1,
        sortbysort: true,
        isdesc: true
      };
      DcVideoApi.lablesV2(_objectSpread({}, params)).then(function (data) {
        _this4.tags = data.list || [];
        // console.log(this.tags);
      }).catch(function () {});
    },
    previewHandleCancel: function previewHandleCancel() {
      this.previewVisible = false;
    },
    getLabelItems: function getLabelItems() {
      var _this5 = this;
      DcVideoApi.getVideoMarkLabel().then(function (data) {
        var preValue = [];
        var qualityvalue = [];
        data.prescriptiontype.forEach(function (val) {
          preValue.push(Number(Object.keys(val)[0]));
        });
        data.qualitytypes.forEach(function (val) {
          qualityvalue.push(Number(Object.keys(val)[0]));
        });
        _this5.prescriptiontypeValue = preValue;
        _this5.prescriptiontype = data.prescriptiontype;
        _this5.qualitytypesValue = qualityvalue;
        _this5.qualitytypes = data.qualitytypes;
      }).catch(function () {});
    }
  }
};