import "core-js/modules/es.array.concat.js";
var searchKeys = [{
  key: 'type',
  label: '',
  placeholder: i18n.tc('dcReview.type'),
  required: false,
  rules: [],
  select: true
  // initialValue: '',
}, {
  key: "videoids",
  label: "",
  placeholder: "".concat(i18n.t('common.postID'), "\uFF0C").concat(i18n.t('common.multipleRowsByEachID')),
  required: false,
  rules: [],
  textarea: true,
  trim: false,
  hidden: true
}, {
  key: "username",
  label: "",
  placeholder: i18n.t('dcReview.searchForm.creatorNickName'),
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "title",
  label: "",
  placeholder: i18n.t('dcReview.searchForm.keywordSearching'),
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "uids",
  label: "",
  placeholder: "".concat(i18n.t('dcReview.searchForm.creatorID'), "\uFF0C").concat(i18n.t('common.multipleRowsByEachID')),
  required: false,
  rules: [],
  textarea: true,
  trim: false,
  hidden: true
}, {
  key: "date",
  label: i18n.t('dcReview.modifiedTime'),
  format: "YYYY-MM-DD HH:mm",
  placeholder: [i18n.t('dcReview.searchForm.beginTime'), i18n.t('dcReview.searchForm.overTime')],
  rules: [],
  dates: true
}, {
  key: "sourcetypes",
  label: "",
  required: false,
  rules: [],
  select: true,
  mode: 'multiple',
  initialValue: undefined,
  placeholder: i18n.t('common.source')
}, {
  key: "markname",
  label: "",
  placeholder: i18n.t('dcReview.searchForm.annotatorName'),
  required: false,
  rules: [],
  input: true,
  trim: false
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };