var columns = [{
  title: 'common.postID',
  dataIndex: 'id',
  key: 'id',
  width: '13%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: 'dcReview.modifiedTime',
  dataIndex: 'updatedtime',
  key: 'updatedtime',
  width: '15%',
  sorter: true,
  scopedSlots: {
    customRender: 'updatedtime'
  }
}, {
  title: 'dcReview.type',
  dataIndex: 'type',
  key: 'type',
  width: '8%',
  scopedSlots: {
    customRender: 'type'
  }
}, {
  title: 'common.postTitle',
  dataIndex: 'name',
  key: 'name',
  width: '20%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: 'dcReview.videoState',
  dataIndex: 'status',
  key: 'status',
  width: '100px',
  scopedSlots: {
    customRender: 'status'
  }
}, {
  title: 'dcReview.source',
  dataIndex: 'sourcetype',
  key: 'sourcetype',
  width: '8%',
  scopedSlots: {
    customRender: 'sourcetype'
  }
}, {
  title: 'dcReview.creator',
  dataIndex: 'username',
  key: 'username',
  width: '120px',
  scopedSlots: {
    customRender: 'username'
  }
}, {
  title: 'dcReview.annotator',
  dataIndex: 'markname',
  key: 'markname',
  width: '120px',
  scopedSlots: {
    customRender: 'markname'
  }
}, {
  title: 'dcReview.state',
  dataIndex: 'state',
  key: 'state',
  width: '100px',
  scopedSlots: {
    customRender: 'state'
  }
}, {
  title: 'common.operation',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: '150px',
  scopedSlots: {
    customRender: 'action'
  }
}];
var logColumns = [{
  title: 'common.logID',
  dataIndex: 'id',
  key: 'id',
  width: '25%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: 'common.createTime',
  dataIndex: 'createtime',
  key: 'createtime',
  width: '20%',
  scopedSlots: {
    customRender: 'createtime'
  }
}, {
  title: 'common.remark',
  dataIndex: 'memo',
  key: 'memo',
  scopedSlots: {
    customRender: 'memo'
  }
}];
export { columns, logColumns };